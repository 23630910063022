import React from 'react';
import styled from '@emotion/styled';
import { Row } from 'antd';

import { color } from '../variables';

const SectionWrapper = styled(Row)`
  padding: 10rem 12rem 6rem;
  background-color: ${color.white};
  background-size: cover;
`;

export const ResponsibleDisclosurePolicy = () => (
  <SectionWrapper>
    <h1>Modern Health's Responsible Disclosure Policy</h1>
    <h3>Last Modified: May 6, 2019</h3>
    <p>
      Data security is a top priority for Modern Health, and Modern Health believes that working
      with skilled security researchers can identify weaknesses in any technology.
    </p>
    <p>
      If you believe you’ve found a security vulnerability in Modern Health’s service, please notify
      us; we will work with you to resolve the issue promptly.
    </p>
    <br />
    <h3>Disclosure Policy</h3>
    <li>
      If you believe you’ve discovered a potential vulnerability, please let us know by emailing us
      at security@joinmodernhealth.com. We will acknowledge your email within One week.
    </li>
    <li>
      Provide us with a reasonable amount of time to resolve the issue before disclosing it to the
      public or a third party. We aim to resolve critical issues within Five business days of
      disclosure.
    </li>
    <li>
      Make a good faith effort to avoid violating privacy, destroying data, or interrupting or
      degrading the Modern Health service. Please only interact with domains you own or for which
      you have explicit permission from the account holder.
    </li>
    <br />
    <h3>Exclusions</h3>
    <p>While researching, we'd like you to refrain from:</p>
    <li> Distributed Denial of Service (DDoS)</li>
    <li>Spamming</li>
    <li>Social engineering or phishing of Modern Health employees or contractors</li>
    <li>Any attacks against Modern Health’s physical property or data centers</li>
    <br />
    <p>Thank you for helping to keep Modern Health and our users safe!</p>
    <br />
    <h3>Changes</h3>
    <p>
      We may revise these guidelines from time to time. The most current version of the guidelines
      will be available at this page (https://www.joinmodernhealth.com/disclosure).
    </p>
    <br />
    <h3>Contact</h3>
    <p>
      Modern Health is always open to feedback, questions, and suggestions. If you would like to
      talk to us, please feel free to email us at security@joinmodernhealth.com.
    </p>
    <br />
    <h3>Responsibility</h3>
    <p>It is the Security team’s responsibility to see this policy is enforced.</p>
  </SectionWrapper>
);

export default ResponsibleDisclosurePolicy;
