import React from 'react';

import Layout from '../components/layout';
import Navigation from '../components/header/Navigation';
import FooterWrapper from '../components/index/FooterWrapper';
import SuggestThisServiceSection from '../components/employees/SuggestThisService';
import ResponsibleDisclosurePolicy from '../components/disclosure/DisclosurePolicyContent';

const ForDisclosurePage = () => (
  <Layout>
    <Navigation />
    <ResponsibleDisclosurePolicy />
    <FooterWrapper children={<SuggestThisServiceSection />} />
  </Layout>
);

export default ForDisclosurePage;
